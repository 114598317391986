import * as React from 'react';
import { Layout } from '../components/Layout';

export default () => {
  return (
    <Layout title={''}>
      Dziękujemy za płatność.<br/>
      Skontaktujemy się z Tobą w przeciągu 24 godzin.
    </Layout>
  );
};
